export const workshopsData = [
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/workshop3.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/workshop1.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/workshop4.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/workshop2.webp",
    ],
    title: "'As I was A walking'",
    subtitle:
      "A workshop in conjunction with Chipping Norton Theatre and FarmED",
  },
];
