export const projectsData = [
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/free-standing1.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/free-standing2.webp",
    ],
    title: "Harvest Wreath",
    subtitle:
      "Harvest wreath center piece corn dolly. Mixed spiral and flat plaits on a straw marquetry base.",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/bow1.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/bow2.webp",
    ],
    title: "Bow",
    subtitle:
      "Created by combining two seperate harp plaits. Finished with dried lavender decoration.",
  },
  {
    photoSrc: ["https://strawcraft.s3.eu-west-2.amazonaws.com/candles.webp"],
    title: "Candles",
    subtitle: "Five straw spiral plait candle decorations.",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/table.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/table2.webp",
    ],
    title: "Table",
    subtitle: "Oak table with staw marquetry inlay.",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/box1.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/box2.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/box3.webp",
    ],
    title: "Jewellery Box",
    subtitle: "Decorated jewellery box, straw marquetry.",
  },
  {
    photoSrc: ["https://strawcraft.s3.eu-west-2.amazonaws.com/butterflys.webp"],
    title: "Butterflies",
    subtitle:
      "Straw marquetry butterflies & flowers presented in a geodesic design pattern.",
  },

  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/hat1.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/hat2.webp",
    ],
    title: "Hat",
    subtitle:
      "Straw hat decoration. Combination of flat plait and Swiss straw work.",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/miniwreaath2.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/mini-wreath.webp",

      "https://strawcraft.s3.eu-west-2.amazonaws.com/miniwreath3.webp",
    ],
    title: "Countryman Favours",
    subtitle: "Assorted favours, ideal for a simple workshop project.",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/tray1.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/tray3.webp",
    ],
    title: "Tray",
    subtitle: "Straw marquetry, decorated tray.",
  },
  {
    photoSrc: ["https://strawcraft.s3.eu-west-2.amazonaws.com/tealights.webp"],
    title: "Tealight holder",
    subtitle: "Triple tealight holder in straw marquetry.",
  },
  {
    photoSrc: ["https://strawcraft.s3.eu-west-2.amazonaws.com/hanging.webp"],
    title: "Drop Corn dollies",
    subtitle: "Examples from a workshop sesion for beginners.",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/church2.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/church4.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/church1.webp",
    ],
    title: "Harvest Church Decorations",
    subtitle: "Traditional neck.",
  },
  {
    photoSrc: [
      "https://strawcraft.s3.eu-west-2.amazonaws.com/church3.webp",
      "https://strawcraft.s3.eu-west-2.amazonaws.com/church5.webp",
    ],
    title: "Welsh border fans",
    subtitle: "Welsh border fans.",
  },
];
