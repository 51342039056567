import React, { useEffect, useState } from "react";
import styles from "./Workshops.module.css";
import { workshopsData } from "./Workshops.entity";
import leftArrow from "../Icons/left.svg";
import rightArrow from "../Icons/right.svg";

function Workshops() {
  const [mouseOutKey, setMouseOutKey] = useState(0);

  const [activeImageIndices, setActiveImageIndices] = useState(
    workshopsData.map(() => 0)
  );

  const [projectArrowVisibility, setProjectArrowVisibility] = useState(
    workshopsData.map(() => false)
  );

  const [touchStartX, setTouchStartX] = useState(null);
  const [touchEndX, setTouchEndX] = useState(null);
  const [touchStartY, setTouchStartY] = useState(null);
  const [touchEndY, setTouchEndY] = useState(null);

  const handleTouchStart = (e, projectIndex) => {
    if (e.touches && e.touches.length > 0) {
      setTouchStartX(e.touches[0].clientX);
      setTouchStartY(e.touches[0].clientY);
      showArrows(projectIndex);
    }
  };

  const handleTouchMove = (e) => {
    setTouchEndX(e.touches[0].clientX);
    setTouchEndY(e.touches[0].clientY);
  };

  const handleTouchEnd = (projectIndex) => {
    if (
      touchStartX !== null &&
      touchEndX !== null &&
      touchStartY !== null &&
      touchEndY !== null
    ) {
      const deltaX = touchEndX - touchStartX;
      const deltaY = touchEndY - touchStartY;
      console.log("dy", deltaY);
      if (deltaX > 50 && deltaY < 50 && deltaY > -50) {
        handlePrevImage(projectIndex);
      } else if (deltaX < -50 && deltaY < 50 && deltaY > -50) {
        handleNextImage(projectIndex);
      }
    }
    setTouchStartX(null);
    setTouchEndX(null);
    setTouchStartY(null);
    setTouchEndY(null);
  };

  const showArrows = (projectIndex) => {
    setProjectArrowVisibility((prevVisibility) => {
      const newVisibility = [...prevVisibility];
      newVisibility[projectIndex] = true;
      return newVisibility;
    });
  };

  const hideArrows = (projectIndex) => {
    setProjectArrowVisibility((prevVisibility) => {
      const newVisibility = [...prevVisibility];
      newVisibility[projectIndex] = false;
      return newVisibility;
    });
  };

  useEffect(() => {
    const arrowTimers = workshopsData.map((_, projectIndex) => {
      return setTimeout(() => hideArrows(projectIndex), 2000);
    });
    return () => {
      arrowTimers.forEach((timer) => clearTimeout(timer));
    };
  }, [activeImageIndices, projectArrowVisibility, touchEndX, mouseOutKey]);

  const handleNextImage = (projectIndex) => {
    setActiveImageIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[projectIndex] =
        (newIndices[projectIndex] + 1) %
        workshopsData[projectIndex].photoSrc.length;
      return newIndices;
    });
    showArrows(projectIndex);
  };

  const handlePrevImage = (projectIndex) => {
    setActiveImageIndices((prevIndices) => {
      const newIndices = [...prevIndices];
      newIndices[projectIndex] =
        (newIndices[projectIndex] -
          1 +
          workshopsData[projectIndex].photoSrc.length) %
        workshopsData[projectIndex].photoSrc.length;
      return newIndices;
    });
    showArrows(projectIndex);
  };

  const handleMouseOver = (projectIndex) => {
    showArrows(projectIndex);
  };

  const handleMouseOut = (projectIndex) => {
    hideArrows(projectIndex);
    setMouseOutKey((prevKey) => prevKey + 1);
  };

  useEffect(() => {
    const preloadImages = () => {
      workshopsData.forEach((project) => {
        project.photoSrc.forEach((photo) => {
          const img = new Image();
          img.src = photo;
        });
      });
    };

    preloadImages();
  }, []);

  return (
    <div className={styles.content}>
      <div className={styles.subtitle}>
        Upcoming Workshop: FarmED - Harvest Festival
      </div>
      <div className={styles.tag}>
        Find out more{" "}
        <a href="https://www.farm-ed.co.uk/event-details/harvest-festival">
          here.
        </a>
      </div>
      <div className={styles.imageContainer}>
        <img
          src={"https://strawcraft.s3.eu-west-2.amazonaws.com/farm-ed.webp"}
          alt={`Farm Ed Poster`}
          className={styles.image}
        />
      </div>

      {workshopsData.map((project, projectIndex) => (
        <div
          key={projectIndex}
          onTouchStart={(e) => handleTouchStart(e, projectIndex)}
        >
          <div className={styles.subtitle}>{project.title}</div>
          <div className={styles.tag}>{project.subtitle}</div>
          <div className={styles.imageContainer}>
            <div onClick={() => handlePrevImage(projectIndex)}>
              <img
                src={leftArrow}
                alt="Previous"
                className={`${styles.leftArrow} ${
                  projectArrowVisibility[projectIndex] &&
                  project.photoSrc.length > 1
                    ? styles["fade-in"]
                    : styles["fade-out"]
                }`}
              />
            </div>
            <img
              src={project.photoSrc[activeImageIndices[projectIndex]]}
              alt={`${project.title} ${activeImageIndices[projectIndex] + 1}`}
              className={styles.image}
              onClick={() => {
                handleNextImage(projectIndex);
              }}
              onTouchStart={(e) => handleTouchStart(e, projectIndex)}
              onTouchMove={handleTouchMove}
              onTouchEnd={() => handleTouchEnd(projectIndex)}
              onMouseOver={() => handleMouseOver(projectIndex)}
              onMouseOut={() => handleMouseOut(projectIndex)}
            />

            <img
              src={rightArrow}
              alt="Next"
              className={`${styles.rightArrow} ${
                projectArrowVisibility[projectIndex] &&
                project.photoSrc.length > 1
                  ? styles["fade-in"]
                  : styles["fade-out"]
              }`}
              onClick={() => handleNextImage(projectIndex)}
            />

            <div className={styles.photoCarousel}>
              {activeImageIndices[projectIndex] + 1}
              {" of "}
              {project.photoSrc.length}
            </div>
          </div>
        </div>
      ))}
      <div className={styles.footerContainer}>
        More information about workshops coming soon...
        <img
          src={"https://strawcraft.s3.eu-west-2.amazonaws.com/desk.webp"}
          alt={`Workshop desk`}
          className={styles.footerImage}
        />
      </div>
    </div>
  );
}

export default Workshops;
